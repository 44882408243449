<template>
  <div>
    <div class="fixed inset-0 darkblue">
      <div class="login-wrapper">
        <h1 class="mb-2 sm:mb-4 text-3xl text-white font-light">Вход в карточки</h1>

        <label for="email" class="label">Электронная почта</label>
        <input v-model="form.email"
               type="email"
               id="email"
               class="input"
               placeholder="alina@example.com"
               @blur="$v.form.email.$touch()">
        <div v-if="$v.form.email.$error"
             class="form-error">
            <p v-if="!$v.form.email.required">
              Это поле не может быть пустым</p>
          <p v-else-if="!$v.form.email.email">
              Email указан с ошибкой :(</p>
        </div>

        <label for="pass" class="label">Пароль</label>
        <input v-model="form.password"
               type="password"
               id="pass"
               class="input"
               placeholder="••••••••"
               @blur="$v.form.password.$touch()">
        <div v-if="$v.form.password.$error"
             class="form-error">
          <p v-if="!$v.form.password.required">
              Это поле не может быть пустым</p>
          <p v-else-if="!$v.form.password.minLength">
              Длина пароля должна быть не менее 8 символов</p>
        </div>

        <button class="mt-10 w-20 h-10 bg-white rounded"
                @click="login">Войти</button>
        <router-link :to="{name: 'PageRegister'}"
                     class="mt-8 text-sm font-light text-white">
          <u>Регистрация</u></router-link>
      </div>
    </div>

    <keypress key-event="keyup" :multiple-keys="keyboard.login" @success="login" />
  </div>
</template>

<script>
import {
  required, email, minLength,
} from 'vuelidate/lib/validators';
import Keypress from 'vue-keypress';

export default {
  components: { Keypress },
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      errors: {
        show: false,
        text: '',
      },
      keyboard: {
        login: [
          { keyCode: 13, modifiers: [], preventDefault: false }, // ENTER
        ],
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  methods: {
    login () {
      this.$v.form.$touch();
      if (!this.$v.$anyError) {
        this.$store.dispatch('auth/loginWithEmailAndPassword', this.form)
            .then(() => this.$router.push('/'))
            .catch((res) => {
              const m = res.response.data.message || res.message;
              alert(m);
            });
      }
    },
  },
}
</script>

<style scoped lang="scss">
.login-wrapper {
  @apply flex flex-col items-center justify-center p-2;
  position: absolute;
  top: 50%; right: 50%;
  transform: translate(50%,-50%);
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 639px) {
    width: 80%;
  }

  @media (min-width: 640px) {
    width: 40%;
  }

  @media (min-width: 1024px) {
    width: 25%;
  }

  @media (min-width: 1536px) {
    width: 18%;
  }
}

.darkblue {
  background: #111122;
}

.input {
  @apply w-full p-2 rounded-lg;
}

.label {
  @apply w-full mt-3 sm:mt-4 mb-1 sm:mb-2 font-semibold text-sm text-white;
}

.form-error {
  @apply w-full mt-1 text-xs font-semibold text-red-600;
}
</style>
